<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-map-marker">
          <v-form
            ref="form"
            lazy-validation
            class="ml-4"
            >
            <v-row>
              <v-col>
                <v-text-field
                  solo
                  :counter="18"
                  label="Username"
                ></v-text-field>

                <v-text-field
                  solo
                  label="Multiple Username"
                ></v-text-field>

                <v-text-field solo
                  label="IP"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field solo
                  label="Session ID"
                ></v-text-field>

                <div class="d-flex">
                  <v-text-field solo
                    class="mr-4"

                  label="Tanggal Awal"
                  ></v-text-field>
                  <v-text-field solo
                  label="Tanggal Akhir"
                  ></v-text-field>
                </div>
                <v-btn
                  color="primary"
                  class="mr-4"
                >
                  <v-icon>mdi-magnify</v-icon>
                  Cari
                </v-btn>
              </v-col>
            </v-row>

          </v-form>
          <crud
            :crudSettings="crudSettings"
            :headers="headers"
            :responseData="{data:items}"
            @onTableChange="updateTable"
          >
          </crud>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from 'lodash'
import Crud from '@/components/common/Crud'

export default {
  components:{
    Crud,
  },

  data(){
    return{
      headers:[
        {text:'Username', value:'username', sortable:false, class:'header-index-profile'},
        {text:'Waktu Login', value:'waktu', sortable:false, class:'header-index-profile'},
        {text:'IP', value:'ip', sortable:false, class:'header-index-profile'},
        {text:'Session ID', value:'sessionID', sortable:false, class:'header-index-profile'},
        {text:'Label', value:'label', sortable:false, class:'header-index-profile'},
      ],
      items:[],

      crudSettings:{
        title:'',
        detailTitle:'Detail',
        deleteTitleKey:'peg_nama',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
        searchLabel:'Filter'
      },
    }
  },

  watch:{},

  created(){
    this.headersTahunan = [
      {text:'No', value:'no', sortable:false, class:'header-index-profile'},
      {text:'Sasaran Strategis', value:'sasaran_strategis', sortable:false, class:'header-index-profile'},
      {text:'Status SKP', value:'status_SKP', sortable:false, class:'header-index-profile'},
      {text:'Target Output', value:'target_output', sortable:false, class:'header-index-profile'},
      {text:'Waktu', value:'waktu', sortable:false, class:'header-index-profile'},
      {text:'Aksi', value:'aksi', sortable:false}
    ]
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)
  },

  computed:{},

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    laporUlang(){},

    updateTable(val){},
  },
}
</script>

<style lang="css" scoped>
</style>
